typeof window !== "undefined" ? require("boxicons") : null;
import PropTypes from "prop-types";
import React from "react";

const FunctionContent = ({ className, image, title, text }) => {
  return (
    <div className={`${className}`}>
      <box-icon name={image} color="#35B554" size="lg" className="my-3" />
      <div className="flex items-center">
        <h3 className="font-bold">{title}</h3>
      </div>
      <p className="text-gray">{text}</p>
    </div>
  );
};

FunctionContent.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};

export default FunctionContent;
