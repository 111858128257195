import { Link } from "gatsby";
import React from "react";
import { useModal } from "react-morphing-modal";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import bannerImage from "../assets/images/background.png";
import Button from "../components/Button";
import Card from "../components/Card";
import FuctionContent from "../components/FunctionContent";
import InformationContent from "../components/InformationContent";
import Layout from "../components/layout";
import SEO from "../components/seo";
import TestModal from "../components/TestModal";

function IndexPage() {
  const { modalProps, getTriggerProps } = useModal({
    background: "#0A1F44",
  });

  return (
    <Layout>
      <SEO title="AtendeJá" />
      <TestModal modalProps={modalProps} />

      <Carousel showStatus={false} showThumbs={false} autoPlay>
        <div
          className="content-container md:flex items-center mb-0"
          style={{
            backgroundColor: "#fafafa",
          }}
        >
          <div className="p-5 md:absolute md:w-3/5 xl:w-1/3 mx-0 md:mx-3 lg:mx-1 xl:mx-10">
            <h1 className="font-bold md:text-white">
              Solução para gestão de atendimentos externos
            </h1>
            <h3 className="md:text-white my-5 font-light">
              Agende atendimentos, monitore sua equipe e analise relatórios com
              agilidade e praticidade.
            </h3>
            <button className="btn my-3 mb-10 btn-blue" {...getTriggerProps()}>
              ENTRAR EM CONTATO
            </button>
          </div>
          <img
            alt=""
            className="hidden md:block ml-auto lg:w-11/12"
            src={bannerImage}
          />
        </div>
      </Carousel>

      <div className="background-white py-8">
        <h1 className="font-bold text-center mt-16 mx-5 md:m-32">
          Conheça as principais funções para gerenciar seus atendimentos
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 gap-8 mx-5 md:mx-20 lg:mx-24 xl:mx-0 md:my-8 mt-10 md:mt-0">
          <FuctionContent
            className="xl:col-start-2"
            title="Atendimentos"
            text="Crie atendimentos para sua equipe com agilidade e praticidade."
            image="wrench"
          />
          <FuctionContent
            title="Agendamento"
            text="Planeje seus atendimentos para cada colaborador ou equipe."
            image="calendar-event"
          />
          <FuctionContent
            title="Monitoramento"
            text="Monitore em tempo real sua equipe e tenha uma visão geral dos atendimentos."
            image="video"
          />
          <FuctionContent
            className="xl:col-start-2"
            title="Mapa"
            text="Localize sua equipe através do mapa de atendimento."
            image="map-alt"
          />
          <FuctionContent
            title="Criação de equipes"
            text="Crie equipes específicas para cada segmento ou área."
            image="group"
          />
          <FuctionContent
            title="Gestão de ativos"
            text="Tenha controle da gestão de produtos e equipamentos de sua empresa."
            image="box"
          />
          <FuctionContent
            className="xl:col-start-2"
            title="Formulário"
            text="Receba atendimentos através de um formulário no site de sua empresa."
            image="comment-detail"
          />
          <FuctionContent
            title="Relatórios"
            text="Acompanhe todas as informações de desempenho dos seus atendimentos."
            image="bar-chart-alt-2"
          />
        </div>
      </div>

      <InformationContent
        className="px-5 md:px-24 xl:px-64 py-24 background-blue"
        title="Entre em contato e comece a gerenciar seus atendimentos"
        description="Fale com nossa equipe comercial para utilizar o AtendeJá e organizar suas atividades de forma rápida e prática"
        textColor="white"
        subTextColor="white"
      />

      <div className="py-10 background-white">
        <h1 className="font-bold text-center py-12 mx-5">
          O aplicativo perfeito para
        </h1>
        <div className="flex grid md:grid-cols-2 xl:grid-cols-4 gap-4 mx-5 md:mx-16 lg:mx-40 xl:mx-0 md:py-12">
          <Card
            className="col-span-1 xl:col-start-2"
            icon="wrench"
            title="Prestação de serviços"
            description="Empresas que prestam serviços e precisam controlar os atendimentos através de uma agenda"
          >
            <h3 className="mb-3 font-bold">Exemplos</h3>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#35B554" />
              <p className="text-gray ml-3">Manutenção e instalação</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#35B554" />
              <p className="text-gray ml-3">Segurança eletrônica</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#35B554" />
              <p className="text-gray ml-3">Refrigeração e climatização</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#35B554" />
              <p className="text-gray ml-3">Provedores de internet</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#35B554" />
              <p className="text-gray ml-3">Dedetizadoras</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#35B554" />
              <p className="text-gray ml-3">Manutenção predial</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#35B554" />
              <p className="text-gray ml-3">Barbearias e salões de beleza</p>
            </div>
            <div className="flex mb-5">
              <box-icon name="check-circle" type="solid" color="#35B554" />
              <p className="text-gray ml-3">Logística e entregas</p>
            </div>
            <Link to="/planos">
              <Button>Saiba mais</Button>
            </Link>
          </Card>
          <Card
            className="col-span-1 xl:col-start-3"
            icon="mobile-alt"
            title="Vendas externas"
            description="Empresas que possuem colaboradores externos diariamente e precisam controlar seus roteiros"
          >
            <h3 className="mb-3 font-bold">Exemplos</h3>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#35B554" />
              <p className="text-gray ml-3">Vendas em campo</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#35B554" />
              <p className="text-gray ml-3">Supervisão comercial</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#35B554" />
              <p className="text-gray ml-3">Distribuidoras e fabricantes</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#35B554" />
              <p className="text-gray ml-3">Trade marketing</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#35B554" />
              <p className="text-gray ml-3">Promotoras de crédito</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#35B554" />
              <p className="text-gray ml-3">Instituições de educação</p>
            </div>
            <div className="flex mb-1">
              <box-icon name="check-circle" type="solid" color="#35B554" />
              <p className="text-gray ml-3">Auditorias</p>
            </div>
            <div className="flex mb-5">
              <box-icon name="check-circle" type="solid" color="#35B554" />
              <p className="text-gray ml-3">Controle de visitas</p>
            </div>
            <Link to="/planos">
              <Button>Saiba mais</Button>
            </Link>
          </Card>
        </div>
      </div>

      <InformationContent
        className="mx-5 md:mx-24 xl:mx-64 my-32"
        title="Junte-se as diversas empresas que confiam no AtendeJá"
        description="Faça parte das empresas que estão crescendo com o AtendeJá e comece a obter melhores resultados"
        subTextColor="gray"
      />
    </Layout>
  );
}

export default IndexPage;
